import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import Layout from "../../components/Layout";

const RESET_PASS_BY_URL = gql`
mutation resetPasswordByUrl($resetUrl: URL!, $password: String!) {
    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
      customer { id }
      customerUserErrors { code field message}
    }
  }
`;

const Reset = ({location}) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const params = new URLSearchParams(location.search);
  const resetUrl = params.get("reset_url");

  const handleSubmit = () => {

    passwordReset({
        variables: { password: password, resetUrl: resetUrl },
        onError(err) {
          console.log(err)
        },
        onCompleted: (result) => {
            console.log(result)
        if (result.customerResetByUrl) {
         if (result.customerResetByUrl.customerUserErrors.length > 0) {
        } else {
               window.location.assign("/account");
             }
           }
        },
      });

  };

    const [passwordReset, {loading}] = useMutation(RESET_PASS_BY_URL);
  return (
    <Layout>
      <main className="container max-w-sm mx-auto px-4 py-8">
        <h1 className="text-center mb-6 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
          Password reset
        </h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your new password
            </label>
            <input
              type="password"
              id="password"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="repeat-password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Repeat password
            </label>
            <input
              type="password"
              id="repeat-password"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
            />
          </div>
          <button
            disabled={loading}
            type="submit"
            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Reset your password
          </button>
        </form>
      </main>
    </Layout>
  );
}

export default Reset;

export const Head = () => <title>Reset Password</title>;
